<template>
  <InfoButton :aria-label="title">
    <template #headline>{{ title }}</template>
    {{ $t(`${base}.text`) }}
  </InfoButton>
</template>

<script lang="ts" setup>
type MyOrdersStatusInfoButtonProps = { status: string; type: string }

const props = defineProps<MyOrdersStatusInfoButtonProps>()

const { t } = useI18n()

const base = computed(
  () => `myorders.status.${props.type}.${props.status}.info`,
)
const title = computed(() => t(`${base.value}.title`))
</script>
